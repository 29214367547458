<div class="container-grid navbar navbar-expand-lg navbar-dark"><a href="index.html" class="responsive-picture picture-link-1">
  <picture>
    <img alt="Placeholder Picture" width="200" height="200" src="./assets/O.png">
  </picture>
</a>
<h3 class="navbar-brand nav-col"><span class="heading-text-2"><span class="heading-text-2" id="heading-text-1">Centre Stage&nbsp;| Reflective Film</span></span></h3><button type="button" class="btn navbar-toggler navbar-toggler-icon button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation"></button><div class="container-grid collapse navbar-collapse" id="navbarNavDropdown">
  <ul class="list-container navbar-nav">
    <li class="list-item-container nav-item">
      <a class="link-text nav-link nav-col nav-size" routerLink="/about" title="About">About</a>
    </li>

    <li class="list-item-container nav-item">
      <a class="link-text nav-link nav-col nav-size" routerLink="/" title="Credits">Online</a>
    </li>
  </ul>
</div>
</div>
<div class="container-grid placeholder-hero index">
  <p class="paragraph hero-tagline size index"><span class="paragraph-text-7" id="paragraph-text-2"><span class="paragraph-text-6">Choose language</span></span></p>
  <div class="container-grid drop-bg-color dropdown" id="Choose-language"><a class="link-button dripdown-toggle-start btn-secondary-start btn-start" href="#" data-toggle="dropdown" aria-expanded="false" aria-haspopup="true" id="dropdownMenuButton-1-copy-2" data-outofview="true"></a>
    <div class="container-grid dropdown-menu dropdown-index-mod" aria-labelledby="dropdownMenuButton-1">
      <nav class="container-grid wrap-one-language">
        <div class="responsive-picture picture-2">
          <picture>
            <img alt="Placeholder Picture" width="200" height="200" src="./assets/6_languages_GER.png">
          </picture>
        </div>
        <a class="link-text dropdown-item start" (click)="visaSprakBlock('DE')">Deutsch</a>
      </nav>
      <nav class="container-grid wrap-one-language">
        <div class="responsive-picture picture-2">
          <picture>
            <img alt="Placeholder Picture" width="200" height="200" src="./assets/6_languages_ENG.png">
          </picture>
        </div>
        <a class="link-text dropdown-item" (click)="visaSprakBlock('EN')" title="Centre Stage Online - Welcome">English</a>
      </nav>
      <nav class="container-grid wrap-one-language">
        <div class="responsive-picture picture-2">
          <picture>
            <img alt="Placeholder Picture" width="200" height="200" src="./assets/6_languages_SPN.png">
          </picture>
        </div>
        <a class="link-text dropdown-item" (click)="visaSprakBlock('SP')">Español</a>
      </nav>
      <nav class="container-grid wrap-one-language">
        <div class="responsive-picture picture-2">
          <picture>
            <img alt="Placeholder Picture" width="200" height="200" src="./assets/6_languages_FRA.png">
          </picture>
        </div>
        <a class="link-text dropdown-item" (click)="visaSprakBlock('FR')">Français</a>
      </nav>
      <nav class="container-grid wrap-one-language" style="display:none;">
        <div class="responsive-picture picture-2">
          <picture>
            <img alt="Placeholder Picture" width="200" height="200" src="./assets/6_languages_POL.png">
          </picture>
        </div>
        <a class="link-text dropdown-item"(click)="visaSprakBlock('PL')">Polskie</a>
      </nav>
      <nav class="container-grid wrap-one-language">
        <div class="responsive-picture picture-2">
          <picture>
            <img alt="Placeholder Picture" width="200" height="200" src="./assets/6_languages_SWE.png">
          </picture>
        </div>
        <a class="link-text dropdown-item" (click)="visaSprakBlock('SV')">Svenska</a>
      </nav>
    </div>
  </div>
</div>
<div class="container-grid language deutsch" *ngIf="tyskablock">
  <header class="container-grid wrap-title-episode">
    <header class="container-grid title-size">
      <h1 class="title-episode">Reflective Film - Deutsch<br></h1>
    </header>
  </header>
  <div class="container-grid con-video-bgr ep1">
    <div class="container-grid con-video-size">
      <div class="responsive-video vimeo con-video-inner" data-original-url="https://vimeo.com/656152637"><iframe src='https://player.vimeo.com/video/656152637' data-original-url='https://vimeo.com/656152637' frameborder='0' webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe></div>
    </div>
  </div>
  <article class="container-grid wrap-main-text-episodes">
    <main class="container-grid container-main-text-episodes">
      <main class="container-grid main-text-episodes">

        <div class="paragraph main-text-ep">
        <h3>Centre Stage | Reflexionen zur Gleichstellung der Geschlechter in der Branche der darstellenden Künste</h3>
            <div style="font-weight:bold">
              <div>Dies ist ein Kurzfilm, der die Diversit&auml;t innerhalb der darstellenden K&uuml;nste reflektiert. Sie k&ouml;nnen es gerne herunterladen, teilen und verwenden, wie Sie m&ouml;chten. Der Film steht mit Untertiteln in f&uuml;nf verschiedenen Sprachen &ndash; Englisch, Schwedisch, Spanisch, Franz&ouml;sisch und Deutsch &ndash; zum Download bereit.</div>
              <br />
              <div>Es hat 5 Kapitel, die auch separat funktionieren:</div>
              <br />
              <div>-Geschlechterstereotypen</div>
              <br />
              <div>-Zugang zu Entscheidungs- und F&uuml;hrungsrollen</div>
              <br />
              <div>-Zugang zu Ressourcen und das geschlechtsspezifische Lohngef&auml;lle</div>
              <br />
              <div>-Zugang zum k&uuml;nstlerischen Arbeitsmarkt</div>
              <br />
              <div>-Sexuelle Bel&auml;stigung</div>
            </div>
            <br />
            <div>Der Film wird im Rahmen des Center Stage Project produziert, das die F&ouml;rderung der Chancengleichheit in den darstellenden K&uuml;nsten zum Ziel hat. Center Stage ist ein europ&auml;isches Kooperationsprojekt f&uuml;r professionelle K&uuml;nstlerinnen in den darstellenden K&uuml;nsten und der Musik und m&ouml;chte zu positiven Ver&auml;nderungen auf individueller und struktureller Ebene beitragen.</div>
            <br />
            <div>Der Film wird von Yolaperdono produziert und von Manuel Jim&eacute;nez N&uacute;&ntilde;ez geschrieben und inszeniert.</div>
            <br />
            <div>Center Stage umfasst auch einen Online-Kurs f&uuml;r darstellende K&uuml;nstlerinnen, die ihre Kommunikations- und k&uuml;nstlerischen F&uuml;hrungsqualit&auml;ten entwickeln m&ouml;chten. Der Kurs ist auf centerstageonline.org in mehreren Sprachen verf&uuml;gbar.</div>
            <br />
            <div>Center Stage wird durch das EU-Programm Creative Europe kofinanziert. Es ist eine Zusammenarbeit zwischen der Verwaltung f&uuml;r kulturelle Entwicklung der Region V&auml;stra G&ouml;taland (Schweden) - centrestage.se</div>
            <br />
            <div>Theaterforum (Irland) &ndash; theatreforum.ie</div>
            <br />
            <div>Agencia Andaluza de Instituciones Culturales (Spanien) - aaiicc.es</div>

          </div>
      </main>
      <div class="container-grid container-3">
        <div class="rule rule-1 upper-refllective">
          <hr>
        </div>
        <h2 class="h2">Stream from Vimeo:</h2>
        <div class="container-grid container-2">
          <a class="link-button btn btn-1002 evaluation full-video" (click)="gotoVimeoViewfilm('full')">
            <span class="button-link-text-7"><span class="button-link-text-7" id="button-link-text-1">Full video</span></span>
          </a>
          <a class="link-button btn btn-1002 evaluation chapter" (click)="gotoVimeoViewfilm('ext1')">
            <font color="#ffffff">Chapter 1</font>
          </a>
          <a class="link-button btn btn-1002 evaluation chapter" (click)="gotoVimeoViewfilm('ext2')">
            <font color="#ffffff">Chapter 2</font>
          </a>
          <a class="link-button btn btn-1002 evaluation chapter" (click)="gotoVimeoViewfilm('ext3')">
            <span class="button-link-text-8"><span class="button-link-text-8" id="button-link-text-2">Chapter 3</span></span>
          </a>
          <a class="link-button btn btn-1002 evaluation chapter" (click)="gotoVimeoViewfilm('ext4')">
            <font color="#ffffff">Chapter 4</font>
          </a>
          <a class="link-button btn btn-1002 evaluation chapter" (click)="gotoVimeoViewfilm('ext5')">
            <font color="#ffffff">Chapter 5</font>
          </a>
        </div>
        <div class="rule rule-2">
          <hr>
        </div>
      </div>
      <div class="container-grid container-3" *ngIf="hideDownloadbuttons">
        <h2 class="h2 reflective">Stream or download from Vimeo:<br></h2>
        <p class="paragraph paragraph-2">(You will be asked two questions on the following page)</p>
        <div class="container-grid container-2">
          <a class="link-button btn btn-1002 evaluation full-video" (click)="openDownloadFilmForm('DE')">
            <span class="button-link-text-7"><span class="button-link-text-7" id="button-link-text-1">Full video</span></span>
          </a>
          <a class="link-button btn btn-1002 evaluation chapter" (click)="openDownloadFilmForm('DE')">
            <font color="#ffffff">Chapter 1</font>
          </a>
          <a class="link-button btn btn-1002 evaluation chapter" (click)="openDownloadFilmForm('DE')">
            <font color="#ffffff">Chapter 2</font>
          </a>
          <a class="link-button btn btn-1002 evaluation chapter"(click)="openDownloadFilmForm('DE')">
            <span class="button-link-text-8"><span class="button-link-text-8" id="button-link-text-2">Chapter 3</span></span>
          </a>
          <a class="link-button btn btn-1002 evaluation chapter" (click)="openDownloadFilmForm('DE')">
            <font color="#ffffff">Chapter 4</font>
          </a>
          <a class="link-button btn btn-1002 evaluation chapter" (click)="openDownloadFilmForm('DE')">
            <font color="#ffffff">Chapter 5</font>
          </a>
        </div>
        <div class="rule rule-2">
          <hr>
        </div>
      </div>
    </main>
  </article>
</div>
<div class="container-grid language english" *ngIf="engelskablock">
  <header class="container-grid wrap-title-episode">
    <header class="container-grid title-size">
      <h1 class="title-episode">Reflective Film - English<br></h1>
    </header>
  </header>
  <div class="container-grid con-video-bgr ep1">
    <div class="container-grid con-video-size">
      <div class="responsive-video vimeo con-video-inner" data-original-url="https://vimeo.com/656146610"><iframe src='https://player.vimeo.com/video/656146610' data-original-url='https://vimeo.com/656146610' frameborder='0' webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe></div>
    </div>
  </div>
  <article class="container-grid wrap-main-text-episodes">
    <main class="container-grid container-main-text-episodes">
      <main class="container-grid main-text-episodes">
        <div class="paragraph main-text-ep">
          <h3>Centre Stage | Gender equality reflections in the performing arts</h3>
          <div style="font-weight:bold">
            <p>This is a short film reflecting on diversity within the performing arts. You&acute;re welcome to download, share and use it however you wish. The film is available for download with subtitles in five different languages &ndash; English, Swedish, Spanish, French and German.</p>
            <p>It has 5 chapters that work separately as well:</p>
            <p>-Gender stereotypes</p>
            <p>-Access to decision-making and leadership roles</p>
            <p>-Access to resources and the gender pay gap</p>
            <p>-Access to the arts labour market</p>
            <p>-Sexual harassment</p>
          </div>
          <p>The film is produced within the Centre Stage Project, aiming to promote equal opportunities in the performing arts. Centre Stage is a European collaborative project for professional women artists in the performing arts and music and aims to contribute to positive change at both individual and structural levels.</p>
          <p>The film is produced by Yolaperdono and written and directed by Manuel Jim&eacute;nez N&uacute;&ntilde;ez.</p>
          <p>Centre Stage also includes an online course for women performing artists who want to develop their communication and artistic leadership skills. The course is available in multiple languages at centrestageonline.org.</p>
          <p>Centre Stage is co-financed by the EU programme Creative Europe. It is a collaboration between the V&auml;stra G&ouml;taland Region's administration for cultural development (Sweden) - centrestage.se Theatre Forum (Ireland) &ndash; theatreforum.ie Agencia Andaluza de Instituciones Culturales (Spain) - aaiicc.e</p>
        </div>
      </main>
      <div class="container-grid container-3">
        <div class="rule rule-1 upper-refllective">
          <hr>
        </div>
        <h2 class="h2">Stream from Vimeo:</h2>
        <div class="container-grid container-2">
          <a class="link-button btn btn-1002 evaluation full-video" (click)="gotoVimeoViewfilm('full')">
            <span class="button-link-text-7"><span class="button-link-text-7" id="button-link-text-1">Full video</span></span>
          </a>
          <a class="link-button btn btn-1002 evaluation chapter" (click)="gotoVimeoViewfilm('ext1')">
            <font color="#ffffff">Chapter 1</font>
          </a>
          <a class="link-button btn btn-1002 evaluation chapter" (click)="gotoVimeoViewfilm('ext2')">
            <font color="#ffffff">Chapter 2</font>
          </a>
          <a class="link-button btn btn-1002 evaluation chapter" (click)="gotoVimeoViewfilm('ext3')">
            <span class="button-link-text-8"><span class="button-link-text-8" id="button-link-text-2">Chapter 3</span></span>
          </a>
          <a class="link-button btn btn-1002 evaluation chapter" (click)="gotoVimeoViewfilm('ext4')">
            <font color="#ffffff">Chapter 4</font>
          </a>
          <a class="link-button btn btn-1002 evaluation chapter" (click)="gotoVimeoViewfilm('ext5')">
            <font color="#ffffff">Chapter 5</font>
          </a>
        </div>
        <div class="rule rule-2">
          <hr>
        </div>
      </div>
      <div class="container-grid container-3" *ngIf="hideDownloadbuttons">
        <h2 class="h2 reflective">Stream or download from Vimeo:<br></h2>
        <p class="paragraph paragraph-2">(You will be asked two questions in the next step)</p>
        <div class="container-grid container-2">
          <a class="link-button btn btn-1002 evaluation full-video"  (click)="openDownloadFilmForm('EN')">
            <span class="button-link-text-7"><span class="button-link-text-7" id="button-link-text-1">Full video</span></span>
          </a>
          <a class="link-button btn btn-1002 evaluation chapter" (click)="openDownloadFilmForm('EN')">
            <font color="#ffffff">Chapter 1</font>
          </a>
          <a class="link-button btn btn-1002 evaluation chapter" (click)="openDownloadFilmForm('EN')">
            <font color="#ffffff">Chapter 2</font>
          </a>
          <a class="link-button btn btn-1002 evaluation chapter" (click)="openDownloadFilmForm('EN')">
            <span class="button-link-text-8"><span class="button-link-text-8" id="button-link-text-2">Chapter 3</span></span>
          </a>
          <a class="link-button btn btn-1002 evaluation chapter" (click)="openDownloadFilmForm('EN')">
            <font color="#ffffff">Chapter 4</font>
          </a>
          <a class="link-button btn btn-1002 evaluation chapter" (click)="openDownloadFilmForm('EN')">
            <font color="#ffffff">Chapter 5</font>
          </a>
        </div>
        <div class="rule rule-2">
          <hr>
        </div>
      </div>
    </main>
  </article>
</div>
<div class="container-grid language espanol" *ngIf="spanskablock">
  <header class="container-grid wrap-title-episode">
    <header class="container-grid title-size">
      <h1 class="title-episode">Reflective Film - Español<br></h1>
    </header>
  </header>
  <div class="container-grid con-video-bgr ep1">
    <div class="container-grid con-video-size">
      <div class="responsive-video vimeo con-video-inner" data-original-url="https://vimeo.com/656161277"><iframe src='https://player.vimeo.com/video/656161277' data-original-url='https://vimeo.com/656161277' frameborder='0' webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe></div>
    </div>
  </div>
  <article class="container-grid wrap-main-text-episodes">
    <main class="container-grid container-main-text-episodes">
      <main class="container-grid main-text-episodes">
        <div class="paragraph main-text-ep">
          <h3>Centre Stage. Reflexiones en torno a la igualdad de g&eacute;nero en las artes esc&eacute;nicas</h3>
          <div style="font-weight:bold">
            <p>Presentamos este cortometraje concebido para reflexionar sobre la igualdad de g&eacute;nero en las artes esc&eacute;nicas. Se puede descargar, compartir y usar libremente. El corto est&aacute; disponible para descargarse en cinco idiomas (ingl&eacute;s, sueco, espa&ntilde;ol, franc&eacute;s y alem&aacute;n)</p>
            <p>Consta de cinco cap&iacute;tulos que funcionan tambi&eacute;n de manera independiente:</p>
            <p>-Estereotipos de g&eacute;nero</p>
            <p>-Acceso a puestos de liderazgo y de toma de decisiones</p>
            <p>-Acceso a financiaci&oacute;n y brecha salarial</p>
            <p>-Acceso al mercado art&iacute;stico</p>
            <p>-Acoso sexual</p>
          </div>
          <p>Este corto se ha producido en el marco del proyecto Centre Stage, que pretende promover la igualdad de oportunidades en las artes esc&eacute;nicas. Centre Stage es un proyecto de cooperaci&oacute;n europea para mujeres artistas profesionales de las artes esc&eacute;nicas y la m&uacute;sica y tiene como objetivo contribuir a un cambio positivo tanto a nivel individual como estructural.</p>
          <p>El corto es una producci&oacute;n de Yolaperdono y est&aacute; escrito y dirigido por Manuel Jim&eacute;nez N&uacute;&ntilde;ez.</p>
          <p>Centre Stage tambi&eacute;n incluye un curso online para mujeres artistas esc&eacute;nicas que quieran desarrollar sus competencias en materia de comunicaci&oacute;n y liderazgo art&iacute;stico. Est&aacute; disponible en <a href="http://www.centrestageonline.org/">http://www.centrestageonline.org/</a></p>
          <p>Centre Stage es un proyecto cofinanciado por el programa Europa Creativa de la UE. Se trata de una colaboraci&oacute;n entre:</p>
          <p>Administraci&oacute;n para el Desarrollo Cultural de la Regi&oacute;n de V&auml;stra G&ouml;taland (Suecia) <a href="http://centrestage.se/">http://centrestage.se/</a></p>
          <p>Theatre Forum (Irlanda) <a href="https://theatreforum.ie/">https://theatreforum.ie/</a></p>
          <p>Agencia Andaluza de Instituciones Culturales (Espa&ntilde;a) <a href="www.aaiicc.es" aria-invalid="true">www.aaiicc.es</a></p>
        </div>
      </main>
      <div class="container-grid container-3">
        <div class="rule rule-1 upper-refllective">
          <hr>
        </div>
        <h2 class="h2">Stream from Vimeo:</h2>
        <div class="container-grid container-2">
          <a class="link-button btn btn-1002 evaluation full-video" (click)="gotoVimeoViewfilm('full')">
            <span class="button-link-text-7"><span class="button-link-text-7" id="button-link-text-1">Full video</span></span>
          </a>
          <a class="link-button btn btn-1002 evaluation chapter" (click)="gotoVimeoViewfilm('ext1')">
            <font color="#ffffff">Chapter 1</font>
          </a>
          <a class="link-button btn btn-1002 evaluation chapter" (click)="gotoVimeoViewfilm('ext2')">
            <font color="#ffffff">Chapter 2</font>
          </a>
          <a class="link-button btn btn-1002 evaluation chapter" (click)="gotoVimeoViewfilm('ext3')">
            <span class="button-link-text-8"><span class="button-link-text-8" id="button-link-text-2">Chapter 3</span></span>
          </a>
          <a class="link-button btn btn-1002 evaluation chapter" (click)="gotoVimeoViewfilm('ext4')">
            <font color="#ffffff">Chapter 4</font>
          </a>
          <a class="link-button btn btn-1002 evaluation chapter" (click)="gotoVimeoViewfilm('ext5')">
            <font color="#ffffff">Chapter 5</font>
          </a>
        </div>
        <div class="rule rule-2">
          <hr>
        </div>
      </div>
      <div class="container-grid container-3" *ngIf="hideDownloadbuttons">
        <h2 class="h2 reflective">Stream or download from Vimeo:<br></h2>
        <p class="paragraph paragraph-2">(You will be asked two questions on the following page)</p>
        <div class="container-grid container-2">
          <a class="link-button btn btn-1002 evaluation full-video"  (click)="openDownloadFilmForm('SP')">
            <span class="button-link-text-7"><span class="button-link-text-7" id="button-link-text-1">Full video</span></span>
          </a>
          <a class="link-button btn btn-1002 evaluation chapter" (click)="openDownloadFilmForm('SP')">
            <font color="#ffffff">Chapter 1</font>
          </a>
          <a class="link-button btn btn-1002 evaluation chapter" (click)="openDownloadFilmForm('SP')">
            <font color="#ffffff">Chapter 2</font>
          </a>
          <a class="link-button btn btn-1002 evaluation chapter" (click)="openDownloadFilmForm('SP')">
            <span class="button-link-text-8"><span class="button-link-text-8" id="button-link-text-2">Chapter 3</span></span>
          </a>
          <a class="link-button btn btn-1002 evaluation chapter" (click)="openDownloadFilmForm('SP')">
            <font color="#ffffff">Chapter 4</font>
          </a>
          <a class="link-button btn btn-1002 evaluation chapter" (click)="openDownloadFilmForm('SP')">
            <font color="#ffffff">Chapter 5</font>
          </a>
        </div>
        <div class="rule rule-2">
          <hr>
        </div>
      </div>
    </main>
  </article>
</div>
<div class="container-grid language francais" *ngIf="franskablock">
  <header class="container-grid wrap-title-episode">
    <header class="container-grid title-size">
      <h1 class="title-episode">Reflective Film - Français<br></h1>
    </header>
  </header>
  <div class="container-grid con-video-bgr ep1">
    <div class="container-grid con-video-size">
      <div class="responsive-video vimeo con-video-inner" data-original-url="https://vimeo.com/656476588"><iframe src='https://player.vimeo.com/video/656476588' data-original-url='https://vimeo.com/656476588' frameborder='0' webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe></div>
    </div>
  </div>
  <article class="container-grid wrap-main-text-episodes">
    <main class="container-grid container-main-text-episodes">
      <main class="container-grid main-text-episodes">
        <div class="paragraph main-text-ep">
          <h3>Centre de la sc&egrave;ne | R&eacute;flexions sur l'&eacute;galit&eacute; des genres dans les arts de la sc&egrave;ne</h3>
          <div style="font-weight:bold">
            <p>Il s'agit d'un court m&eacute;trage refl&eacute;tant la diversit&eacute; au sein des arts de la sc&egrave;ne. Vous pouvez le t&eacute;l&eacute;charger, le partager et l'utiliser comme bon vous semble. Le film est disponible en t&eacute;l&eacute;chargement avec des sous-titres en cinq langues diff&eacute;rentes : anglais, su&eacute;dois, espagnol, fran&ccedil;ais et allemand. Il comporte 5 chapitres qui fonctionnent &eacute;galement s&eacute;par&eacute;ment :</p>
            <p>-St&eacute;r&eacute;otypes de genre</p>
            <p>-Acc&egrave;s &agrave; des r&ocirc;les d&eacute;cisionnels et de leadership</p>
            <p>-Acc&egrave;s aux ressources et &eacute;cart de r&eacute;mun&eacute;ration entre les sexes</p>
            <p>-Acc&egrave;s au march&eacute; du travail artistique</p>
            <p>-Harc&egrave;lement sexuel</p>
          </div>
          <p>Le film est produit dans le cadre du Center Stage Project, qui vise &agrave; promouvoir l'&eacute;galit&eacute; des chances dans les arts de la sc&egrave;ne. Center Stage est un projet collaboratif europ&eacute;en pour les femmes artistes professionnelles des arts de la sc&egrave;ne et de la musique et vise &agrave; contribuer &agrave; un changement positif aux niveaux individuel et structurel. Le film est produit par Yolaperdono et &eacute;crit et r&eacute;alis&eacute; par Manuel Jim&eacute;nez N&uacute;&ntilde;ez.</p>
          <p>Center Stage comprend &eacute;galement un cours en ligne pour les femmes artistes de la sc&egrave;ne qui souhaitent d&eacute;velopper leurs comp&eacute;tences en communication et en leadership artistique. Le cours est disponible en plusieurs langues sur <a href="www.centrestageonline.org" aria-invalid="true">www.centrestageonline.org</a></p>
          <p>Center Stage est cofinanc&eacute; par le programme europ&eacute;en Creative Europe. Il s'agit d'une collaboration entre l'administration du d&eacute;veloppement culturel de la r&eacute;gion de V&auml;stra G&ouml;taland (Su&egrave;de) - centerstage.se Theatre Forum (Irlande) &ndash; theatreforum.ie Agencia Andaluza de Instituciones Culturales (Espagne) - aaiicc.es</p>
        </div>
      </main>
      <div class="container-grid container-3">
        <div class="rule rule-1 upper-refllective">
          <hr>
        </div>
        <h2 class="h2">Stream from Vimeo:</h2>
        <div class="container-grid container-2">
          <a class="link-button btn btn-1002 evaluation full-video" (click)="gotoVimeoViewfilm('full')">
            <span class="button-link-text-7"><span class="button-link-text-7" id="button-link-text-1">Full video</span></span>
          </a>
          <a class="link-button btn btn-1002 evaluation chapter" (click)="gotoVimeoViewfilm('ext1')">
            <font color="#ffffff">Chapter 1</font>
          </a>
          <a class="link-button btn btn-1002 evaluation chapter" (click)="gotoVimeoViewfilm('ext2')">
            <font color="#ffffff">Chapter 2</font>
          </a>
          <a class="link-button btn btn-1002 evaluation chapter" (click)="gotoVimeoViewfilm('ext3')">
            <span class="button-link-text-8"><span class="button-link-text-8" id="button-link-text-2">Chapter 3</span></span>
          </a>
          <a class="link-button btn btn-1002 evaluation chapter" (click)="gotoVimeoViewfilm('ext4')">
            <font color="#ffffff">Chapter 4</font>
          </a>
          <a class="link-button btn btn-1002 evaluation chapter" (click)="gotoVimeoViewfilm('ext5')">
            <font color="#ffffff">Chapter 5</font>
          </a>
        </div>
        <div class="rule rule-2">
          <hr>
        </div>
      </div>
      <div class="container-grid container-3" *ngIf="hideDownloadbuttons">
        <h2 class="h2 reflective">Stream or download from Vimeo:<br></h2>
        <p class="paragraph paragraph-2">(You will be asked two questions on the following page)</p>
        <div class="container-grid container-2">
          <a class="link-button btn btn-1002 evaluation full-video"  (click)="openDownloadFilmForm('FR')">
            <span class="button-link-text-7"><span class="button-link-text-7" id="button-link-text-1">Full video</span></span>
          </a>
          <a class="link-button btn btn-1002 evaluation chapter" (click)="openDownloadFilmForm('FR')">
            <font color="#ffffff">Chapter 1</font>
          </a>
          <a class="link-button btn btn-1002 evaluation chapter" (click)="openDownloadFilmForm('FR')">
            <font color="#ffffff">Chapter 2</font>
          </a>
          <a class="link-button btn btn-1002 evaluation chapter" (click)="openDownloadFilmForm('FR')">
            <span class="button-link-text-8"><span class="button-link-text-8" id="button-link-text-2">Chapter 3</span></span>
          </a>
          <a class="link-button btn btn-1002 evaluation chapter" (click)="openDownloadFilmForm('FR')">
            <font color="#ffffff">Chapter 4</font>
          </a>
          <a class="link-button btn btn-1002 evaluation chapter" (click)="openDownloadFilmForm('FR')">
            <font color="#ffffff">Chapter 5</font>
          </a>
        </div>
        <div class="rule rule-2">
          <hr>
        </div>
      </div>
    </main>
  </article>
</div>
<div class="container-grid language polskie" *ngIf="polskablock">
  <header class="container-grid wrap-title-episode">
    <header class="container-grid title-size">
      <h1 class="title-episode">Reflective Film - Polskie<br></h1>
    </header>
  </header>
  <div class="container-grid con-video-bgr ep1">
    <div class="container-grid con-video-size">
      <div class="responsive-video vimeo con-video-inner" data-original-url="https://vimeo.com/185475993"><iframe src='https://player.vimeo.com/video/185475993' data-original-url='https://vimeo.com/185475993' frameborder='0' webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe></div>
    </div>
  </div>
  <article class="container-grid wrap-main-text-episodes">
    <main class="container-grid container-main-text-episodes">
      <main class="container-grid main-text-episodes">
        <p class="paragraph main-text-ep">This is text.&nbsp;This is text.&nbsp;This is text. This is text. This is text. This is text.&nbsp;This is text. This is text. This is text. This is text. This is text. This is text. &nbsp; &nbsp;</p>
      </main>
      <div class="container-grid container-3">
        <div class="rule rule-1 upper-refllective">
          <hr>
        </div>
        <h2 class="h2">Stream from Vimeo:</h2>
        <div class="container-grid container-2">
          <a class="link-button btn btn-1002 evaluation full-video" (click)="gotoVimeoViewfilm('full')">
            <span class="button-link-text-7"><span class="button-link-text-7" id="button-link-text-1">Full video</span></span>
          </a>
          <a class="link-button btn btn-1002 evaluation chapter" (click)="gotoVimeoViewfilm('ext1')">
            <font color="#ffffff">Chapter 1</font>
          </a>
          <a class="link-button btn btn-1002 evaluation chapter" (click)="gotoVimeoViewfilm('ext2')">
            <font color="#ffffff">Chapter 2</font>
          </a>
          <a class="link-button btn btn-1002 evaluation chapter" (click)="gotoVimeoViewfilm('ext3')">
            <span class="button-link-text-8"><span class="button-link-text-8" id="button-link-text-2">Chapter 3</span></span>
          </a>
          <a class="link-button btn btn-1002 evaluation chapter" (click)="gotoVimeoViewfilm('ext4')">
            <font color="#ffffff">Chapter 4</font>
          </a>
        </div>
        <div class="rule rule-2">
          <hr>
        </div>
      </div>
      <div class="container-grid container-3" *ngIf="hideDownloadbuttons">
        <h2 class="h2 reflective">Stream or download from Vimeo:<br></h2>
        <p class="paragraph paragraph-2">(You will be asked two questions on the following page)</p>
        <div class="container-grid container-2">
          <a class="link-button btn btn-1002 evaluation full-video"  (click)="openDownloadFilmForm('PL')">
            <span class="button-link-text-7"><span class="button-link-text-7" id="button-link-text-1">Full video</span></span>
          </a>
          <a class="link-button btn btn-1002 evaluation chapter" (click)="openDownloadFilmForm('PL')">
            <font color="#ffffff">Chapter 1</font>
          </a>
          <a class="link-button btn btn-1002 evaluation chapter" (click)="openDownloadFilmForm('PL')">
            <font color="#ffffff">Chapter 2</font>
          </a>
          <a class="link-button btn btn-1002 evaluation chapter" (click)="openDownloadFilmForm('PL')">
            <span class="button-link-text-8"><span class="button-link-text-8" id="button-link-text-2">Chapter 3</span></span>
          </a>
          <a class="link-button btn btn-1002 evaluation chapter" (click)="openDownloadFilmForm('PL')">
            <font color="#ffffff">Chapter 4</font>
          </a>
        </div>
        <div class="rule rule-2">
          <hr>
        </div>
      </div>
    </main>
  </article>
</div>
<div class="container-grid language svenska" *ngIf="svenskablock">
  <header class="container-grid wrap-title-episode">
    <header class="container-grid title-size">
      <h1 class="title-episode">Reflective Film - Svenska<br></h1>
    </header>
  </header>
  <div class="container-grid con-video-bgr ep1">
    <div class="container-grid con-video-size">
      <div class="responsive-video vimeo con-video-inner" data-original-url="https://vimeo.com/656489269"><iframe src='https://player.vimeo.com/video/656489269' data-original-url='https://vimeo.com/656489269' frameborder='0' webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe></div>
    </div>
  </div>
  <article class="container-grid wrap-main-text-episodes">
    <main class="container-grid container-main-text-episodes">
      <main class="container-grid main-text-episodes">
        <div class="paragraph main-text-ep">
          <h3>Centre Stage | Reflektioner &ouml;ver j&auml;mst&auml;lldheten i scenkonstbranschen</h3>
          <div style="font-weight:bold">
            <p>Det h&auml;r &auml;r en kortfilm f&ouml;r reflektion om j&auml;mst&auml;lldhet inom scenkonstbranschen. Hj&auml;rtligt v&auml;lkommen att ladda ner, dela och anv&auml;nd precis som du vill. Filmen finns tillg&auml;nglig med undertexter p&aring; fem olika spr&aring;k &ndash; engelska, svenska, spanska, franska och tyska.</p>
            <p>Filmen finns ocks&aring; uppdelad i fem kapitel som kan anv&auml;ndas frist&aring;ende:</p>
            <p>- Stereotypa k&ouml;nsroller</p>
            <p>- Tillg&aring;ng till beslutsfattande och ledande positioner</p>
            <p>- Tillg&aring;ng till resurser samt l&ouml;neklyftor</p>
            <p>- Tillg&aring;ng till arbetsmarknaden</p>
            <p>- Sexuella trakasserier</p>
          </div>
          <p>Filmen &auml;r en del av projektet Centre Stage, som str&auml;var efter en j&auml;mst&auml;lld scenkonstbransch. Centre Stage &auml;r ett europeiskt samarbetsprojekt f&ouml;r professionellt yrkesverksamma kvinnor inom scenkonst och musik, och ska bidra till en positiv f&ouml;r&auml;ndring b&aring;de f&ouml;r individen och p&aring; en strukturell niv&aring;.</p>
          <p>Filmen &auml;r producerad av Yolaperdono och skriven och regisserad av Manuel Jim&eacute;nez N&uacute;&ntilde;ez.</p>
          <p>Projektet Centre Stage best&aring;r ocks&aring; av en onlinekurs f&ouml;r kvinnor inom scenkonsten som vill utvecklas inom kommunikation och sitt konstn&auml;rliga ledarskap. Kursen finns p&aring; flera spr&aring;k, den &auml;r gratis och webbaserad. Du hittar den p&aring; centrestageonline.org</p>
          <p>Centre Stage &auml;r medfinansierat av EU-programmet Kreativa Europa. Det &auml;r ett samarbetsprojekt mellan:<br />V&auml;stra G&ouml;talandsregionens f&ouml;rvaltning f&ouml;r kulturutveckling (Sverige): centrestage.se och vgr.se/kulturutveckling<br />Theatre Forum (Irland) &ndash; theatreforum.ie<br />Agencia Andaluza de Instituciones Culturales (Spanien) - aaiicc.es</p>
        </div>
      </main>
      <div class="container-grid container-3">
        <div class="rule rule-1 upper-refllective">
          <hr>
        </div>
        <h2 class="h2">Stream from Vimeo:</h2>
        <div class="container-grid container-2">
          <a class="link-button btn btn-1002 evaluation full-video" (click)="gotoVimeoViewfilm('full')">
            <span class="button-link-text-7"><span class="button-link-text-7" id="button-link-text-1">Full video</span></span>
          </a>
          <a class="link-button btn btn-1002 evaluation chapter" (click)="gotoVimeoViewfilm('ext1')">
            <font color="#ffffff">Chapter 1</font>
          </a>
          <a class="link-button btn btn-1002 evaluation chapter" (click)="gotoVimeoViewfilm('ext2')">
            <font color="#ffffff">Chapter 2</font>
          </a><a class="link-button btn btn-1002 evaluation chapter" (click)="gotoVimeoViewfilm('ext3')">
            <span class="button-link-text-8"><span class="button-link-text-8" id="button-link-text-2">Chapter 3</span></span>
          </a>
          <a class="link-button btn btn-1002 evaluation chapter" (click)="gotoVimeoViewfilm('ext4')">
            <font color="#ffffff">Chapter 4</font>
          </a>
          <a class="link-button btn btn-1002 evaluation chapter" (click)="gotoVimeoViewfilm('ext5')">
            <font color="#ffffff">Chapter 5</font>
          </a>
        </div>
        <div class="rule rule-2">
          <hr>
        </div>
      </div>
      <div class="container-grid container-3" *ngIf="hideDownloadbuttons">
        <h2 class="h2 reflective">Stream or download from Vimeo:<br></h2>
        <p class="paragraph paragraph-2">(You will be asked two questions on the following page)</p>
        <div class="container-grid container-2">
          <a class="link-button btn btn-1002 evaluation full-video"  (click)="openDownloadFilmForm('SV')">
            <span class="button-link-text-7"><span class="button-link-text-7" id="button-link-text-1">Full video</span></span>
          </a>
          <a class="link-button btn btn-1002 evaluation chapter" (click)="openDownloadFilmForm('SV')">
            <font color="#ffffff">Chapter 1</font>
          </a>
          <a class="link-button btn btn-1002 evaluation chapter" (click)="openDownloadFilmForm('SV')">
            <font color="#ffffff">Chapter 2</font>
          </a>
          <a class="link-button btn btn-1002 evaluation chapter" (click)="openDownloadFilmForm('SV')">
            <span class="button-link-text-8"><span class="button-link-text-8" id="button-link-text-2">Chapter 3</span></span>
          </a>
          <a class="link-button btn btn-1002 evaluation chapter" (click)="openDownloadFilmForm('SV')">
            <font color="#ffffff">Chapter 4</font>
          </a>
          <a class="link-button btn btn-1002 evaluation chapter" (click)="openDownloadFilmForm('SV')">
            <font color="#ffffff">Chapter 5</font>
          </a>
        </div>
        <div class="rule rule-2">
          <hr>
        </div>
      </div>
    </main>
  </article>
</div>

<div class="container-grid" *ngIf="downloadForm">
  <!-- <header class="container-grid wrap-title-episode">
    <header class="container-grid title-size">
      <h1 class="title-episode"*ngIf="tyskablock">Reflective Film tyska<br></h1>
    </header>
  </header> -->
  <article class="container-grid wrap-main-text-episodes reflective">
    <main class="container-grid container-main-text-episodes reflective">
      <div class="container-grid container-1">
        <main class="container-grid main-text-episodes reflective">
          <h2 class="h2 reflective">We are so delighted that you want to download the film!<br></h2>
          <p class="paragraph main-text-ep">In order for us to get an idea of how the film is distributed and in what contexts it is used, we are grateful that you answer two questions before you go to the Vimeo download page. The information is important for us to be able to evaluate the project.<br><br>Thank you!</p>
        </main>
      </div>
      <div class="container-grid container-3">
        <div class="rule rule-1 upper-refllective">
          <hr>
        </div>
        <h2 class="h2 reflective">Stream or download from Vimeo:<br></h2>
        <p class="paragraph paragraph-2">Please answer these two questions to be able to download.</p>
        <form [formGroup]="downloadFormgroup"  class="form-container form-container-2">
          <p class="paragraph paragraph-3">Which organisation do you represent?</p>
          <input formControlName="txtorg" value="" name="Q1" type="text" class="input-5">
          <p class="paragraph paragraph-4">If you intend to show the film, in which context is that?</p>
          <input formControlName="txtintend" value="" name="Q2" type="text" class="input-6">
        </form>
        <div class="container-grid container-2">

          <a [class.disabled]="downloadFormgroup.valid ? null: true" (click)="gotoVimeoDownload('full')" class="link-button btn btn-1002 evaluation full-video" >
            <span class="button-link-text-7"><span class="button-link-text-7" id="button-link-text-1">Full video</span></span>
          </a>
          <a [class.disabled]="downloadFormgroup.valid ? null: true" (click)="gotoVimeoDownload('ext1')" class="link-button btn btn-1002 evaluation chapter">
            <font color="#ffffff">Chapter 1</font>
          </a>
          <a [class.disabled]="downloadFormgroup.valid ? null: true" (click)="gotoVimeoDownload('ext2')" class="link-button btn btn-1002 evaluation chapter">
            <font color="#ffffff">Chapter 2</font>
          </a>
          <a [class.disabled]="downloadFormgroup.valid ? null: true" (click)="gotoVimeoDownload('ext3')" class="link-button btn btn-1002 evaluation chapter">
            <span class="button-link-text-8"><span class="button-link-text-8" id="button-link-text-2">Chapter 3</span></span>
          </a>
          <a [class.disabled]="downloadFormgroup.valid ? null: true" (click)="gotoVimeoDownload('ext4')" class="link-button btn btn-1002 evaluation chapter" >
            <font color="#ffffff">Chapter 4</font>
          </a>
          <a [class.disabled]="downloadFormgroup.valid ? null: true" (click)="gotoVimeoDownload('ext5')" class="link-button btn btn-1002 evaluation chapter" >
            <font color="#ffffff">Chapter 5</font>
          </a>
        </div>
        <div class="rule rule-2">
          <hr>
        </div>
      </div>
    </main>
  </article>
</div>

<footer class="container-grid footer-container-wrap footer-no-margin">
  <p class="paragraph text-footer"><span class="paragraph-text-1"><span class="paragraph-text-1" id="paragraph-text-1">
    <span class="paragraph-text-2">Information and contact | Centre Stage</span><br>Kristina Johansson,&nbsp;Project Coordinator<br>kristina.ac.johansson@vgregion.se <br></span></span></p>
  <div class="responsive-picture picture-1">
    <picture><img alt="Co-funded by the Createve Europe Programme of the European Union" src="./assets/EU-logga_600.png" title="Co-funded by the Createve Europe Programme of the European Union"></picture>
  </div>
</footer>
