import { coursenavLanguage } from './models/coursenavLanguage';
import { AuthGuardStart } from './shared/autguardStart/authstart.guard';
import { cookieLanguageHandler } from './models/cookieLanguageHandler';
import { registerUserFormData } from './models/RegisteruserFormData';
import { AuthGuard } from './shared/authguard/auth.guard';
import { LectionRouterModule } from './lections/lection-router/lection-router.module';
import { AppGlobalErrorHandler } from './../services/errorhandlers/appGlobalErrorHandler';
import { ErrorHandler } from '@angular/core';
import { Global } from './models/global';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainnavComponent } from './shared/mainnav/mainnav.component';
import { CoursenavComponent } from './lections/coursenav/coursenav.component';
import { MainfooterComponent } from './shared/mainfooter/mainfooter.component';
import { HttpClientModule } from '@angular/common/http';
import { LocalStorageHandler } from './models/localstorageHandler';
import { redirectHandler } from './models/redirecthandler';
import { GenericLectionsComponent } from './lections/generic-lections/generic-lections.component';
import { StartComponent } from './start/start.component';
import { Err404pageComponent } from './shared/err404page/err404page.component';
import { LectionMainComponent } from './lections/lection-main/lection-main.component';
import { RegisterComponent } from './register/register.component';
import { SafePipe } from './shared/pipes/safe.pipe';
import { OverviewComponent } from './overview/overview.component';
import { GenericMainpageComponent } from './generic-mainpage/generic-mainpage.component';
import { AboutComponent } from './about/about.component';
import { CreditsComponent } from './credits/credits.component';
import { WelcomeComponent } from './welcome/welcome/welcome.component';
import { EvaluationComponent } from './lections/evaluation/evaluation.component';
import { EpisodesstartComponent } from './lections/episodesstart/episodesstart.component';
import { FilmSharedComponent } from './film-shared/film-shared.component';
import { FilmDownloadComponent } from './filmShared/film-download/film-download.component';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';

const cookieConfig:NgcCookieConsentConfig = {
  cookie: {
    domain: 'localhost' //'www.centrestageonline.org' //'dev.centrestageonline.org' //'katalog.frontdata.se' //'localhost'// it is recommended to set your domain, for cookies to work properly
  },
  palette: {
    popup: {
      background: '#222'
    },
    button: {
      background: '#111'
    }
  },
  theme: 'classic',
  type: 'opt-out',
  layout: 'my-custom-layout',
  layouts: {
    "my-custom-layout": '{{messagelink}}{{compliance}}'
  },
  elements:{
    messagelink: `
    <span id="cookieconsent:desc" class="cc-message">{{message}}<p>
      <a aria-label="learn more about cookies" class="cc-link" href="{{cookiePolicyHref}}" target="_blank" rel="noreferrer">{{cookiePolicyLink}}</a>
      </p></span>
    `,
  },
  content:{
    message: 'På centrestageonline.org använder vi cookies för att ge dig en bra användarupplevelse. Genom att bläddra vidare godkänner du att vi använder cookies och browserstorage.',
 
    cookiePolicyLink: 'Läs mer om cookie/kakor',
    cookiePolicyHref: 'https://www.cookiesandyou.com/',
 
    privacyPolicyLink: 'Privacy Policy',
    privacyPolicyHref: 'https://privacy.com',
 
    tosLink: 'hantering av Service',
    tosHref: 'https://www.centrestageonline.org/',
    header: 'Kakor används på denna webbplats. Cookies used on the website!',
    dismiss: 'avböj!',
    allow: 'Tillåt kakor/ cookies',
    deny: 'Avböj',
    link: 'läs mer',
    policy: 'Kakor Policy'
  }
};

@NgModule({
  declarations: [
    AppComponent,
    MainnavComponent,
    CoursenavComponent,
    MainfooterComponent,
    GenericLectionsComponent,
    StartComponent,
    Err404pageComponent,
    LectionMainComponent,
    RegisterComponent,  
    SafePipe, OverviewComponent, GenericMainpageComponent, AboutComponent, CreditsComponent, 
    WelcomeComponent, EvaluationComponent, EpisodesstartComponent, FilmSharedComponent, FilmDownloadComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,   
    NgcCookieConsentModule.forRoot(cookieConfig), 
    // CookieLawModule,
    HttpClientModule,   
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    Global,
    AuthGuard,
    AuthGuardStart,
    registerUserFormData,
    coursenavLanguage,
    cookieLanguageHandler,
    LocalStorageHandler,
    redirectHandler,    
    {provide: ErrorHandler, useClass: AppGlobalErrorHandler}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
