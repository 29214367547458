import { Event, NavigationEnd, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import {Global} from "./models/global";
import { NgcCookieConsentService, NgcNoCookieLawEvent, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import * as e from 'express';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'CentreStageOnline';
  showlang:string =""
  cookieText:string;
  cookieLinktext:string;
  showaltmeny:boolean=false;
  popupOpenSubscription: Subscription = new Subscription;

 
  private popupCloseSubscription!: Subscription;
  private initializingSubscription!: Subscription;
  private initializedSubscription!: Subscription;
  private initializationErrorSubscription!: Subscription;
  private statusChangeSubscription!: Subscription;
  private revokeChoiceSubscription!: Subscription;
  private noCookieLawSubscription!: Subscription;

  constructor(private glb:Global,public router: Router, private ccService:NgcCookieConsentService, private cookieHandler: CookieService
    ) {
    this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd){
        gtag('config', 'UA-7175122-15', {'page_path': event.urlAfterRedirects});
      }
    })

   
  }

  ngOnInit() {
   
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.checkDenycookies();// kolla om cookies är tillåtna och ta bort alla om inte
        console.log("NavigationEnd");
        this.showaltmeny =false;
        if ((<NavigationEnd>event).url =="/reflectivefilm"){
          this.showaltmeny = true;
        };
      }
    });
    this.popupOpenSubscription= this.ccService.popupOpen$.subscribe(()=>{ });
    this.glb.currentLanguageHandler.subscribe(()=>{
      this.addcookietext();

    });
    this.addcookietext();
    //  localStorage.setItem('userlang',"SV" );

    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        console.log("statusChangeSubscription",event);
        if (event.status == "deny"){
          //delete all CookieService    
          console.log("statusChangeSubscription deny");
          this.cookieHandler.deleteAll();      
          this.cookieHandler.set("cookieconsent_status", "deny");

        }else {
          console.log("statusChangeSubscription allow");
        }
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
      () => {
        console.log("revokeChoiceSubscription");
        // you can use this.ccService.getConfig() to do stuff...
      });

      this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
      (event: NgcNoCookieLawEvent) => {
        console.log("noCookieLaw",event);
        // you can use this.ccService.getConfig() to do stuff...
      });

  }


  addcookietext(){
    let cookieobj = this.glb.getCookieText();
    this.cookieText= cookieobj.cookietext;
    this.cookieLinktext= cookieobj.linktext;
    this.ccService.getConfig().content = this.ccService.getConfig().content || {} ;
        // Override default messages with the translated ones
        this.ccService.getConfig().content.header = cookieobj.linktext;
        this.ccService.getConfig().content.message = cookieobj.cookietext;
        this.ccService.getConfig().content.dismiss = cookieobj.deny;
        this.ccService.getConfig().content.allow = cookieobj.allow;
        this.ccService.getConfig().content.deny = cookieobj.deny;
        this.ccService.getConfig().content.cookiePolicyLink = cookieobj.cookiePolicyLink;
        // this.ccService.getConfig().content.policy = "cookie AJ policy";

        this.ccService.destroy(); // remove previous cookie bar (with default messages)
        this.ccService.init(this.ccService.getConfig()); // update config with translated messages

  }

  checkDenycookies(){
    // kollar om cookies är tillåtna och ta bort alla om inte
    if(this.cookieHandler.get("cookieconsent_status")=="deny"){
      this.cookieHandler.deleteAll();
      this.cookieHandler.set("cookieconsent_status", "deny");
    }     
  }
 
}
